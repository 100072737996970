var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-tab", {
        attrs: {
          type: "vertical",
          tabItems: _vm.tabItems,
          height: _vm.tabHeight,
        },
        on: {
          "update:height": function ($event) {
            _vm.tabHeight = $event
          },
          tabClick: _vm.tabClick,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  key: tab.key,
                  tag: "component",
                  attrs: {
                    param: _vm.param,
                    click: _vm.click,
                    disabledObj: _vm.disabledObj,
                  },
                  on: {
                    "update:param": function ($event) {
                      _vm.param = $event
                    },
                    "update:click": function ($event) {
                      _vm.click = $event
                    },
                    "update:disabledObj": function ($event) {
                      _vm.disabledObj = $event
                    },
                    "update:disabled-obj": function ($event) {
                      _vm.disabledObj = $event
                    },
                    getEquips: _vm.getEquips,
                    setKey: _vm.setKey,
                    disabledSet: _vm.disabledSet,
                    stepBystep2: _vm.stepBystep2,
                    closePopup: _vm.closePopup,
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }